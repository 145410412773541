.btn-reset {
    background: none;
    border: none;
    text-shadow: none;
    margin: 0;
    padding: 0;
    text-align: center;
    border-radius: 5px;

    &:focus {
        outline: none;
    }
}

.btn-nav-link {
    @extend .btn-reset;
}

.btn-as-link {
    display: inline;
    color: $link_color;
    cursor: pointer;
}

.active {
    color: $link_color;
    svg path.icon {
        fill: $link_color;
    }
}

// button,
// .btn {
//     height: 3.25rem;
//     font-weight: 600;
//     font-size: 1rem;
//     letter-spacing: -0.015em;
//     margin: 1em 0;
//     text-align: left;
//     padding-left: 1em;
//     display: inline-block;
//     margin-right: 5px;
//     text-shadow: 0 1px 0 rgba($black, 0.2);
//     &.btn-cancel {
//         background: none;
//         text-shadow: none;
//         text-align: center;
//         opacity: 0.4;
//     }
// }

.btn {
    @extend .btn-reset;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.125rem;
    color: white;
    text-shadow: 0px 1px 0px rgba(black, 0.2);
    min-height: 3.25rem;
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: left;
    padding: 1.1rem 1rem 0.9rem 1rem;
    text-decoration: none;
    display: flex;
    cursor: pointer;
    transition: background-color 0.3s cubic-bezier(0, 0.5, 0.6, 1);

    &:active {
        text-decoration: none;
        color: white;
    }

    &:hover {
        text-decoration: none;
        color: white;
        text-shadow: 0px 1px 0px rgba(black, 0.4);
    }

    .icon {
        font-weight: normal;
        font-size: 2rem;
	margin-top: -0.1em;
        margin-right: 0.75rem;
    }

    @include for-size($tablet-landscape-down) {
        display: flex;
        width: 100%;
    }

    @include for-size($tablet-landscape-up) {
        display: inline-flex;
        width: 100%;
    }
}

.btn-primary,
a.btn-primary {
    @extend .btn;
    background-color: $primary;

    &:active {
        background-color: lighten($primary, 10%);
    }

    &:hover {
        background-color: lighten($primary, 5%);
    }
}

.btn-secondary {
    @extend .btn;
    background-color: $secondary;

    &:active {
        background-color: lighten($secondary, 10%);
    }

    &:hover {
        background-color: lighten($secondary, 5%);
    }
}

.btn-success {
    @extend .btn;

    background-color: $success;

    &:active {
        background-color: lighten($success, 10%);
    }

    &:hover {
        background-color: lighten($success, 5%);
    }
}

a.btn-primary {
    @extend button;
    @extend .btn-primary;
    -webkit-appearance: none;
    // text-decoration: none;
    // color: white;
}

.btn-danger {
    @extend .btn;
    background-color: $danger;

    &:active {
        background-color: lighten($danger, 10%);
    }

    &:hover {
        background-color: lighten($danger, 10%);
    }
}

.btn-outline {
    @extend .btn;
    background: none;
    border: 1px solid $grey_cold_1;
    color: $grey_cold_3;
    text-shadow: none;
    transition: border-color, color 0.3s cubic-bezier(0, 0.5, 0.6, 1);

    &:active {
        color: darken($grey_cold_3, 10%);
        border-color: darken($grey_cold_3, 10%);
    }

    &:hover {
        text-decoration: none;
        color: darken($grey_cold_3, 10%);
        border-color: darken($grey_cold_1, 10%);
        text-shadow: none;
    }
}

.btn-outline-strong {
    @extend .btn-outline;
    background: white;
    color: $grey_cold_6;
    box-shadow: 0px 2px 9px rgba($grey_cold_3, 0.4);
    border: 2px solid $grey_cold_1;
    transition: border-color 0.3s cubic-bezier(0, 0.5, 0.6, 1);

    &:active {
        color: darken($grey_cold_3, 10%);
        border-color: darken($grey_cold_3, 10%);
        // background-color: darken(white, 1%);
        box-shadow: 0px 2px 2px rgba($grey_cold_3, 0.4);
    }

    &:hover {
        color: darken($grey_cold_6, 10%);
        border-color: darken($grey_cold_3, 10%);
        // background-color: darken(white, 1%);
        box-shadow: 0px 2px 2px rgba($grey_cold_3, 0.4);
        text-shadow: none;
    }
}

.big-action {
    font-size: 1.5rem;
    opacity: 0.3;
}

.btn-center {
    text-align: center;
}

button.btn {
    margin-top: 0;
}

.btn-cookie-consent {
    @extend .btn;

    &:active {
        background-color: lighten($primary, 10%);
    }

    &:hover {
        background-color: lighten($primary, 5%);
    }

    @include for-size($tablet-landscape-up) {
        width: auto;
    }

    background-color: #325AFF;
}
