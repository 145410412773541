.filter-form {
    padding-top: 1rem;

    .default-filter-checkbox {
        margin-bottom: 1rem;
        input {
            height: 0;
        }

        label {
            text-indent: 1.5em;
            display: inline;
            cursor: pointer;
            color: $brand-blue;
            span {
                text-decoration: underline;
            }

            .star-icon {
                line-height: 1rem;
                font-size: 2rem;
                color: $grey_cold_3;

                &.active {
                    color: $button-blue;
                }
            }

            input {
                display: none;
            }
        }
    }
}

@include for-size($tablet-landscape-down) {
    .filter-form {
        .actions {
            margin-bottom: $mobile-margin-bottom;
        }
    }
}

@include for-size($tablet-landscape-up) {
    .filter-form {
        .actions {
            display: flex;
            flex-direction: row;

            .btn {
                margin-right: 1rem;
            }

            .btn:last-child {
                margin-right: 0;
            }
        }
    }
}
