.event-detail {
    box-sizing: border-box;
    bottom: 0;
    padding-bottom: 1rem;
    color: $black;
    display: block;
    width: 100%;

    h3 {
        font-family: 'Poppins';
        font-size: 1.375rem;
        font-weight: 600;
        line-height: 1.3em;
        letter-spacing: -0.025em;
        margin: 1rem 0.25rem;
    }

    h4 {
        font-family: 'Poppins';
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.125em;
        letter-spacing: -0.005em;
        margin: 1rem 0.25rem;
        padding: 1rem 0;
    }

    h5 {
        font-family: 'Poppins';
        font-size: 1rem;
        font-weight: 600;
        text-transform: uppercase;
    }

    section {
        color: $black;
        padding: 0 1rem;
        box-sizing: border-box;
    }

    .header {
        padding: 0;

        .datetime-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between; // grid-template-columns: auto 96px;
            background-color: black;
            color: white;

            .date-container {
                flex-grow: 1;
                font-size: 0.875rem;
                line-height: 1rem;
                align-self: center;
                padding-left: 1rem;

                .day {
                    text-transform: capitalize;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    font-weight: 400;
                }

                .dateline {
                    font-family: 'Poppins';
                    font-size: 1.125rem;
                    font-weight: 600;
                }
            }

            .date-block {
                padding: 1rem 0;
                font-size: 1rem;
                line-height: 1.2rem;
                width: 110px;
                min-height: 5.125rem;

                .date {
                    color: white;
                }
            }
        }
    }

    .info, .date-block.datetime {
        background-color: $primary;
	.date, .allday {
            color: $white;
	}
    }
    .info {
	font-family: 'Poppins';
	display: inline-block;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 0.8125em;
	padding: 0.25em 0.5em 0.1em 0.5em;
	border-radius: 2px;
	margin-bottom: 1em;
    }

    // @TODO: fonction pour statut de message : affichage correct pour dates non confirmées
    .status-message.tbc {
	&:before {
	    font-family: 'UI thin';
	    color: $grey_cold_6;
	    font-size: 1.5em;
	    margin-right: 0.4em;
	    content: '?';
	}

	font-family: 'Poppins';
	font-size: 1em;
	padding: 0.5em 1em 0.5em 1em;
	border-radius: 2px;
	margin-bottom: 1em;
	font-weight: 600;
	color: $grey_cold_6;
	background-color: $white;
	box-shadow: 0 2px 10px 0 rgba(142,156,164,0.50);
    }

    &.status-postponed, &.status-canceled {
	.datetime-wrapper  {
	    text-decoration: line-through;
	}

	.info {
	    background-color: transparent;
	    text-transform: none;
	    width: 100%;
	    border: 1px solid #D0DBE2;
	    box-shadow: 0 2px 10px 0 rgba(142,156,164,0.50);
	    padding: 0;

	    .status-message {
		&:before {
		    @extend .refused-icon;
		    font-family: 'UI thin';
		    color: $refused;
		    font-size: 1.5em;
		    margin-right: 0.4em;
		}
		padding: 1em;
		font-size: 1em;
		color: $grey_cold_6;
		background-color: $grey_cold_1;
	    }

	    .show-event-link {
		&:before {
		    @extend .slide-right-icon;
		    font-family: 'UI thin';
		    font-size: 1.5em;
		    margin-right: 0.4em;
		}
		padding: 1em;
		background-color: $white;
		a {
		    color: $grey_cold_7;
		}
	    }

	    margin-bottom: 1.5em;
	}

	.content h3 {
	    color: $grey_cold_3;
	}
    }

    &.status-topnews {
	.info, .date-block.datetime {
	    color: $white;
	    background-color: $topnews;
	    .date, .allday {
		color: $white;
	    }
	}
    }

    &.status-feature {
	.info, .date-block.datetime {
	    background-color: $feature;
	    .date, .allday {
		color: $black;
	    }
	}
    }

    .location {
        padding: 1rem;
        line-height: 1.2rem;
        color: black;
        font-size: 0.9375rem;
        font-weight: 600;
        background-color: $white;
    }

    .content {
        border-top: 1px solid $border-color;
        background-color: $white;
        padding: 1rem;
        // padding-bottom: 0;

        h3 {
            margin: 0;
            margin-bottom: 1rem;
        }

        .description {
            font-family: 'Quicksand';
            font-size: 1.125rem;
            line-height: 1.5rem;
            font-weight: 400;
            color: $grey_cold_6;
            margin-bottom: 1rem;
            white-space: pre-line;
        }
    }

    .coverages {
        border-top: 1px solid $border-color;
        padding: 1rem;

        h4 {
            margin: 0;
            margin-bottom: 1rem;
        }

        ul {
            padding: 0;
            list-style: none;
            font-size: 1rem;
            font-family: 'Quicksand';
            font-weight: 600;
            color: $grey_cold_6;
            margin: 0;

            li {
                padding: 0.5rem 0rem;
                border-top: 1px solid $border-color;
                align-items: center;
                line-height: 2rem;

                .collapse-header {
		    display: flex;

		    .icon {
			margin-right: 0.4em;
			font-size: 2em;
		    }

		    .done-icon, .accepted-icon {
			color: $button-green;
		    }

		    .refused-icon {
			color: $refused;
		    }

		    .undecided-icon {
			color: $undecided;
		    }

		    .count {
			//@TODO: alignement à droite
			background-color: $button-blue;
			font-size: 0.75em;
			color: $white;
			display: block;
			width: 28px;
			height: 28px;
			line-height: 28px;
			text-align: center;
			border-radius: 16px;
			font-family: 'Poppins';
			font-weight: 600;
			text-shadow: 0 2px 0 rgba(0,0,0,0.21);
			margin-top: 0.1875em;
		    }
                }

                &.active {
                    color: $grey_cold_7;
                }

                &.inactive {
                    color: $grey_cold_5;
                }

                .icon {
                    font-size: 1.5rem;
                }
            }
        }

	.prev {
	    display: flex;
	    margin-bottom: 1.375em;

	    &:first-child {
		margin-top: 1.375em;
	    }

	    .day {
		font-family: 'Poppins';
		font-size: 0.825em;
		font-weight: 600;
		color: $white;
		background-color: $grey_cold_5;
		letter-spacing: -0.025em;
		text-align: center;
		border-radius: 2px;
		padding: 0.125em 0.625em;
		height: 1.7em;
		line-height: 1.7em;
		margin-right: 1.125em;
	    }

	    .hour-attribute {
		font-family: 'Poppins';
		font-size: 0.875em;
		font-weight: 600;
		color: $black;
		line-height: 1.7em;
	    }

	    .label {
		font-family: 'Quicksand';
		font-size: 0.875em;
		font-weight: 600;
		color: $grey_cold_6;
		text-align: left;
		line-height: 1.325em;
	    }
	}
    }

    // .iptcs {
    //     border-top: 1px solid $border-color;
    //     padding: 1rem;

    //     h4 {
    //         margin: 0;
    //         margin-bottom: 1rem;
    //     }

    //     .iptc {
    //         display: inline-block;
    //         color: $white;
    //         font-size: 0.75rem;
    //         font-family: RobotoMono;
    //         background-color: mix($grey_cold_2, $grey_cold_3, 50%);
    //         padding: 0.5em 0.75em;
    //         border-radius: 2px;
    //         margin: 0 5px 5px 0;
    //     }
    // }
}

@include for-size($tablet-portrait-up) {
}

@include for-size($tablet-landscape-down) {
    .event-detail {
        section {
            &.back {
                margin-bottom: $mobile-margin-bottom;
            }
        }
    }
}

@include for-size($tablet-landscape-up) {
    .event-detail {
        padding: 0 2rem;
        .share {
            margin: 2rem 0 1rem 0;
            display: flex;
            flex-direction: row;
            .btn {
                margin-right: 1rem;
            }
            .btn:last-child {
                margin-right: 0;
            }
        }

        .datetime {
            flex: none;
        }

        .header .datetime-wrapper .date-block {
            width: 31%;
            min-width: 110px;

            .from.date,
            .to.date {
                font-size: 1rem;
            }
        }

        section {
            max-width: $desktop-max-width;
            margin: auto;
            &.header .datetime-wrapper .date-container {
                padding-left: $header-height;
            }
            &.content,
            &.location,
            &.coverages {
                padding: $header-height;
            }

            &.location {
                padding-top: 2.35rem;
                padding-bottom: 2.35rem;
            }

            &.coverages {
                padding: 1.5rem $header-height;
		.prev {
		    .label {
			font-size: 1.125em;
			line-height: 1.325em;
			font-weight: 400;
		    }
		}
            }

            &.back {
                margin-bottom: $header-height-desktop;
            }
        }
    }
}
