.organizer {
    display: grid;
    grid-template-rows: $header-height auto;

    header {
        grid-area: header;
    }

    aside {
        grid-area: sidebar;
    }

    main {
        grid-area: main;
    }
}

@include for-size($tablet-landscape-down) {
    .organizer {
        grid-template-columns: 100vw;
        grid-template-areas:
            'header'
            'main';
    }
}

@include for-size($tablet-landscape-up) {
    .organizer {
        grid-template-columns: $aside-width auto;
        grid-template-rows: $header-height-desktop auto;
        grid-template-areas:
            '. header'
            'sidebar main';
    }
}

.loading {
    text-align: center;
    text-shadow: none;

    .text {
        opacity: 0.2;
        font-size: 2rem;
    }

    .spinner {
        box-sizing: border-box;
        position: relative;
        width: 9rem;
        height: 9rem;
        margin: 3rem auto;
        border-radius: 50%;
        border: 1px solid rgba(black, 0.2);
        border-top-color: $link_color;
        animation: spinner 0.6s linear infinite;
    }

    @keyframes spinner {
        to {
            transform: rotate(360deg);
        }
    }
}

.more-results button {
    .loading {
	margin-left: 3em;
	text-align: right;

	.text {
	    display: none;
	}

	.spinner {
	    float: left;
	    right: 1rem;
	    width: 1.3rem;
	    height: 1.3rem;
	    margin: 0;
	}
    }
}
