@import 'variables';
@import 'bootstrap-custom';
@import 'mixins';
@import 'icons';
@import 'fonts';
@import 'config';
@import 'button';
@import 'form';
@import 'date';
@import 'layout';
@import 'event-list';
@import 'event-detail';
@import 'overlay';
@import 'share';
@import 'login';
@import 'rules';
@import 'list';
@import 'filter-form';
@import 'navbar';
@import 'profile';
@import 'help';
@import 'search-form';
@import 'sidebar';
@import 'react-add-calendar';

// z-index : (ne pas changer sans bien comprendre les implications)
// 1 pour body
// 2 pour les jours de la liste des événements
// 3 pour le menu latéral et la modale du détail d'un événement
// 4 pour le header

body {
    font-family: 'Quicksand';
    -webkit-overflow-scrolling: touch;
}

.organizer {
    background-color: $main-bg;
    height: 100vh;
}

h1,
h2,
h3,
h4 {
    font-family: 'Poppins';
    font-weight: 600;
}
h1 {
    font-size: 1.8rem;
    line-height: 1.8rem;
    letter-spacing: -0.05rem;
    // font-weight: 400;
}
h2 {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
}
h3 {
    font-size: 1.125rem;
}

h4 {
    font-size: 1rem;
}

.tabs .nav-link {
    cursor: pointer;
}

.info-wrapper {
    text-align: center;
    padding: 1rem;
    .info {
        font-size: 0.8rem;
        text-align: center;
        margin: 1rem 0;
    }
}

.content-wrapper {
    padding: 1rem;
}

@include for-size($tablet-landscape-up) {
    .content-wrapper {
        max-width: $desktop-max-width;
        margin: auto;
    }
}

#browser-not-supported {
    width: 100%;
    min-height: 100vh;
    background-color: $black;
    color: $white;
    padding-top: 20%;

    .list {
        text-align: center;
    }
}
