@import 'mixins';

.overlay {
    background-color: $main-bg;
    grid-column: 2/3;
    grid-row: 2/3;
    z-index: 10;
    position: fixed;
    top: $header-height;
    height: calc(100vh - #{$header-height});
    overflow-y: auto;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;

    @include for-size($tablet-landscape-down) {
        left: 0;
        width: 100%;
    }

    @include for-size($tablet-landscape-up) {
        left: $aside-width;
        width: calc(100% - #{$aside-width});
        top: $header-height-desktop;
        height: calc(100vh - #{$header-height-desktop});
    }
}
