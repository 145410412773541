%UI-icon {
    font-style: normal;
    font-family: 'UI thin';
    text-decoration: none;
    text-rendering: optimizeLegibility;
    white-space: nowrap;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    -moz-font-feature-settings: 'liga=1';
    -moz-font-feature-settings: 'liga';
    -ms-font-feature-settings: 'liga' 1;
    -o-font-feature-settings: 'liga';
    font-feature-settings: 'liga';
    font-size: 16px;
}

%UI-plus:before {
    content: '\002b';
}
%UI-hyphen:before {
    content: '\002d';
}
%UI-add:before {
    content: '\f055';
}
%UI-arrow-down:before {
    content: '\2193';
}
%UI-arrow-down-left:before {
    content: '\2199';
}
%UI-arrow-down-right:before {
    content: '\2198';
}
%UI-arrow-left:before {
    content: '\2190';
}
%UI-arrow-right:before {
    content: '\2192';
}
%UI-arrow-up:before {
    content: '\2191';
}
%UI-arrow-up-left:before {
    content: '\2196';
}
%UI-arrow-up-right:before {
    content: '\2197';
}
%UI-attach:before {
    content: '\f159';
}
%UI-book:before {
    content: '\f02c';
}
%UI-camera:before {
    content: '\1f4f7';
}
%UI-close:before {
    content: '\2421';
}
%UI-collapse:before {
    content: '\25b4';
}
%UI-delete:before {
    content: '\f057';
}
%UI-expand:before {
    content: '\25be';
}
%UI-fontsize:before {
    content: '\f034';
}
%UI-forbidden:before {
    content: '\f05e';
}
%UI-list:before {
    content: '\e9a1';
}
%UI-location:before {
    content: '\f041';
}
%UI-lock:before {
    content: '\1f512';
}
%UI-mail:before {
    content: '\2709';
}
%UI-mosaic:before {
    content: '\e9a0';
}
%UI-navigate-down:before {
    content: '\f107';
}
%UI-navigate-left:before {
    content: '\f104';
}
%UI-navigate-right:before {
    content: '\f105';
}
%UI-navigate-up:before {
    content: '\f106';
}
%UI-parameter:before {
    content: '\f0ad';
}
%UI-pause-video:before {
    content: '\f145';
}
%UI-play-video:before {
    content: '\f144';
}
%UI-remove:before {
    content: '\f056';
}
%UI-search:before {
    content: '\f002';
}
%UI-share:before {
    content: '\ee00';
}
%UI-slide-active:before {
    content: '\f102';
}
%UI-slide-inactive:before {
    content: '\f103';
}
%UI-swipe-horizontaly:before {
    content: '\f200';
}
%UI-tag:before {
    content: '\f02b';
}
%UI-tap:before {
    content: '\f201';
}
%UI-time:before {
    content: '\f266';
}
%UI-type:before {
    content: '\f031';
}
%UI-unlock:before {
    content: '\f15d';
}
%UI-lolcat:before {
    content: '\f257';
}
%UI-comment:before {
    content: '\f151';
}
%UI-left:before {
    content: '\21e6';
}
%UI-right:before {
    content: '\21e8';
}
%UI-up:before {
    content: '\21e7';
}
%UI-down:before {
    content: '\21e9';
}
%UI-top-of-page:before {
    content: '\21ea';
}
%UI-music:before {
    content: '\266b';
}
%UI-edit:before {
    content: '\270e';
}
%UI-compose:before {
    content: '\f044';
}
%UI-writedisable:before {
    content: '\2710';
}
%UI-rss:before {
    content: '\f09e';
}
%UI-bookmark:before {
    content: '\1f516';
}
%UI-retweet:before {
    content: '\f079';
}
%UI-favorite-inactive:before {
    content: '\2729';
}
%UI-reply:before {
    content: '\f112';
}
%UI-settings:before {
    content: '\f013';
}
%UI-verified:before {
    content: '\f058';
}
%UI-accepted:before {
    content: '\2713';
}
%UI-refused:before {
    content: '\2715';
}
%UI-undecided:before {
    content: '?';
}
%UI-bulletlist:before {
    content: '\f0ca';
}
%UI-todo:before {
    content: '\f0cb';
}
%UI-switch-active:before {
    content: '\f231';
}
%UI-switch-inactive:before {
    content: '\f230';
}
%UI-send:before {
    content: '\f113';
}
%UI-loading:before {
    content: '\f110';
}
%UI-photos:before {
    content: '\f03d';
}
%UI-ticket:before {
    content: '\1f3ab';
}
%UI-level:before {
    content: '\f012';
}
%UI-access:before {
    content: '\f255';
}
%UI-photo:before {
    content: '\f03e';
}
%UI-agenda:before {
    content: '\1f5d5';
}
%UI-prevision:before {
    content: '\1f554';
}
%UI-pictures:before {
    content: '\f03c';
}
%UI-play:before {
    content: '\25b6';
}
%UI-pause:before {
    content: '\e8a0';
}
%UI-fast-forward:before {
    content: '\23e9';
}
%UI-fast-rewind:before {
    content: '\23ea';
}
%UI-fullscreen:before {
    content: '\f065';
}
%UI-expand-video:before {
    content: '\f067';
}
%UI-reduce-video:before {
    content: '\f066';
}
%UI-audio-on:before {
    content: '\f253';
}
%UI-audio-off:before {
    content: '\f15a';
}
%UI-audio-on-alt:before {
    content: '\f15b';
}
%UI-audio-off-alt:before {
    content: '\f254';
}
%UI-hat-tip:before {
    content: '\21ac';
}
%UI-embed:before {
    content: '\f121';
}
%UI-hd:before {
    content: '\f147';
}
%UI-sd:before {
    content: '\f148';
}
%UI-hard-drive:before {
    content: '\f0a0';
}
%UI-upload:before {
    content: '\eb41';
}
%UI-download:before {
    content: '\eb01';
}
%UI-bookmarks:before {
    content: '\f02e';
}
%UI-pin:before {
    content: '\f158';
}
%UI-align-to-right:before {
    content: '\f038';
}
%UI-align-to-left:before {
    content: '\f036';
}
%UI-justify:before {
    content: '\f039';
}
%UI-stock-decrease:before {
    content: '\f25a';
}
%UI-stock-increase:before {
    content: '\f25b';
}
%UI-pie-charts:before {
    content: '\e570';
}
%UI-charts:before {
    content: '\f153';
}
%UI-cloud:before {
    content: '\2601';
}
%UI-refreshing:before {
    content: '\eb82';
}
%UI-winner-badge:before {
    content: '\f091';
}
%UI-favorite-badge:before {
    content: '\f0a3';
}
%UI-note:before {
    content: '\f156';
}
%UI-compose-note:before {
    content: '\f155';
}
%UI-rewind:before {
    content: '\27f2';
}
%UI-note-disable:before {
    content: '\f154';
}
%UI-checked-mail:before {
    content: '\270a';
}
%UI-refresh:before {
    content: '\27f3';
}
%UI-live:before {
    content: '\f146';
}
%UI-rss-button:before {
    content: '\f143';
}
%UI-live-broadcasting:before {
    content: '\f149';
}
%UI-swipe-right:before {
    content: '\f204';
}
%UI-twitter:before {
    content: '\f099';
}
%UI-twitter-button:before {
    content: '\f081';
}
%UI-watch:before {
    content: '\231a';
}
%UI-flag:before {
    content: '\2691';
}
%UI-print:before {
    content: '\f02f';
}
%UI-sharealt:before {
    content: '\f14d';
}
%UI-work:before {
    content: '\f152';
}
%UI-flash:before {
    content: '\26a1';
}
%UI-locate:before {
    content: '\f124';
}
%UI-drag-horizontaly:before {
    content: '\f07e';
}
%UI-drag-verticaly:before {
    content: '\f07d';
}
%UI-expand-right:before {
    content: '\25b9';
}
%UI-expand-left:before {
    content: '\25c3';
}
%UI-slide-right:before {
    content: '\25b7';
}
%UI-slide-left:before {
    content: '\25c1';
}
%UI-slide-up:before {
    content: '\25b3';
}
%UI-slide-down:before {
    content: '\25bd';
}
%UI-close-alt:before {
    content: '\2715';
}
%UI-anchorlink:before {
    content: '\2693';
}
%UI-permalink:before {
    content: '\f258';
}
%UI-mic-active:before {
    content: '\f130';
}
%UI-mic-inactive:before {
    content: '\f131';
}
%UI-like:before {
    content: '\f14a';
}
%UI-dislike:before {
    content: '\f14b';
}
%UI-meeting:before {
    content: '\f068';
}
%UI-heart:before {
    content: '\2665';
}
%UI-circle:before {
    content: '\25cb';
}
%UI-notification:before {
    content: '\f15e';
}
%UI-stock-equal:before {
    content: '\f259';
}
%UI-stop:before {
    content: '\25a0';
}
%UI-user-male:before {
    content: '\f14e';
}
%UI-user-female:before {
    content: '\f14f';
}
%UI-users:before {
    content: '\f150';
}
%UI-notification-off:before {
    content: '\f15f';
}
%UI-swipe-verticaly:before {
    content: '\f202';
}
%UI-swipe-right:before {
    content: '\f204';
}
%UI-swipe-left:before {
    content: '\f205';
}
%UI-swipe-up:before {
    content: '\f206';
}
%UI-swipe-down:before {
    content: '\f207';
}
%UI-gesture:before {
    content: '\f203';
}
%UI-info:before {
    content: '\2139';
}
%UI-help:before {
    content: '\f059';
}
%UI-warning:before {
    content: '\26ae';
}
%UI-laptop:before {
    content: '\f109';
}
%UI-desktop:before {
    content: '\f108';
}
%UI-mobile:before {
    content: '\f10c';
}
%UI-tablet:before {
    content: '\f10a';
}
%UI-responsive:before {
    content: '\f10b';
}
%UI-key-up:before {
    content: '\f210';
}
%UI-key-down:before {
    content: '\f211';
}
%UI-key-left:before {
    content: '\f212';
}
%UI-key-right:before {
    content: '\f213';
}
%UI-pressed-key-up:before {
    content: '\f220';
}
%UI-pressed-key-down:before {
    content: '\f221';
}
%UI-pressed-key-right:before {
    content: '\f223';
}
%UI-pressed-key-left:before {
    content: '\f222';
}
%UI-homepage:before {
    content: '\f015';
}
%UI-feedback:before {
    content: '\1f4ac';
}
%UI-readingtime:before {
    content: '\f256';
}
%UI-center:before {
    content: '\f037';
}
%UI-spread:before {
    content: '\f208';
}
%UI-pinch:before {
    content: '\f209';
}
%UI-zoom:before {
    content: '\f00e';
}
%UI-unzoom:before {
    content: '\f010';
}
%UI-minus:before {
    content: '\2212';
}
%UI-star:before {
    content: '\22c7';
}
%UI-spacer:before {
    content: '\007c';
}
%UI-tickets:before {
    content: '\f250';
}
%UI-more:before {
    content: '\2026';
}
%UI-step-forward:before {
    content: '\f051';
}
%UI-step-backward:before {
    content: '\f048';
}
%UI-facebook:before {
    content: '\f09a';
}
%UI-facebook-button:before {
    content: '\f082';
}
%UI-checked:before {
    content: '\2713';
}
%UI-sharp:before {
    content: '\f000';
}
%UI-male:before {
    content: '\2642';
}
%UI-female:before {
    content: '\2640';
}
%UI-google-plus:before {
    content: '\f083';
}
%UI-google-plus-button:before {
    content: '\f084';
}
%UI-linkedin-button:before {
    content: '\f080';
}
%UI-id:before {
    content: '\f263';
}
%UI-instagram:before {
    content: '\f085';
}
%UI-book:before {
    content: '\f02c';
}
%UI-directions:before {
    content: '\f261';
}
%UI-bookalt:before {
    content: '\f02a';
}
%UI-credit-card:before {
    content: '\f251';
}
%UI-compas:before {
    content: '\f267';
}
%UI-inbox:before {
    content: '\f264';
}
%UI-lol:before {
    content: '\f268';
}
%UI-menu:before {
    content: '\f265';
}
%UI-ibooks:before {
    content: '\f02d';
}
%UI-settings-alt:before {
    content: '\2699';
}
%UI-history:before {
    content: '\f262';
}
%UI-caddy:before {
    content: '\f260';
}
