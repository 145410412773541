$phone-only: phone-only;
$tablet-landscape-down: tablet-landscape-down;
$tablet-portrait-up: tablet-portrait-up;
$tablet-landscape-up: tablet-landscape-up;
$desktop-up: desktop-up;
$big-desktop-up: big-desktop-up;

@mixin for-size($range) {
    $phone-upper-boundary: 600px;
    $tablet-portrait-upper-boundary: 900px;
    $tablet-landscape-upper-boundary: 1200px;
    $desktop-upper-boundary: 1800px;

    @if $range == $phone-only {
        @media (max-width: #{$phone-upper-boundary - 1}) {
            @content;
        }
    } @else if $range == $tablet-landscape-down {
        @media (max-width: #{$tablet-portrait-upper-boundary - 1}) {
            @content;
        }
    } @else if $range == $tablet-portrait-up {
        @media (min-width: $phone-upper-boundary) {
            @content;
        }
    } @else if $range == $tablet-landscape-up {
        @media (min-width: $tablet-portrait-upper-boundary) {
            @content;
        }
    } @else if $range == $desktop-up {
        @media (min-width: $tablet-landscape-upper-boundary) {
            @content;
        }
    } @else if $range == $big-desktop-up {
        @media (min-width: $desktop-upper-boundary) {
            @content;
        }
    }
}
