.react-add-to-calendar {

  .react-add-to-calendar__wrapper {
    .react-add-to-calendar__button {
      @extend a.btn-primary;
    }
  }

  .react-add-to-calendar__dropdown {
    background-color: white;
    margin-top: -1rem;
    border-radius: 5px;
    border: 1px solid $grey-cold-1;
    margin-bottom: 1rem;

    ul {
      margin: 0;
      list-style: none;
      padding: 0.5rem 1rem;
      li {
        a {
          &:hover {
            text-decoration: none;
          }
          i {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

}