$font_path: './fonts/';

// // *********** //
// // SOURCE SANS //
// // ITALIC
// @font-face {
//     font-family: 'Source Sans Pro';
//     src: url($font_path+'SourceSansPro-Italic.eot'); /* IE9 Compat; Modes */
//     src: url($font_path+'SourceSansPro-Italic.eot?#iefix') format('embedded-opentype'),
//         /* IE6-IE8 */ url($font_path+'SourceSansPro-Italic.woff2') format('woff2'),
//         /* Modern Browsers */ url($font_path+'SourceSansPro-Italic.woff') format('woff'); /* Modern Browsers */
//     font-style: italic, oblique;
//     font-weight: 400;
//     text-rendering: optimizeLegibility;
// }
// // REGULAR
// @font-face {
//     font-family: 'Source Sans Pro';
//     src: url($font_path+'SourceSansPro-Regular.eot'); /* IE9 Compat; Modes */
//     src: url($font_path+'SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
//         /* IE6-IE8 */ url($font_path+'SourceSansPro-Regular.woff2') format('woff2'),
//         /* Modern Browsers */ url($font_path+'SourceSansPro-Regular.woff') format('woff'); /* Modern Browsers */
//     font-style: normal;
//     font-weight: 400;
//     text-rendering: optimizeLegibility;
// }
// // SEMI BOLD
// @font-face {
//     font-family: 'Source Sans Pro';
//     src: url($font_path+'SourceSansPro-Semibold.eot'); /* IE9 Compat; Modes */
//     src: url($font_path+'SourceSansPro-Semibold.eot?#iefix') format('embedded-opentype'),
//         /* IE6-IE8 */ url($font_path+'SourceSansPro-Semibold.woff2') format('woff2'),
//         /* Modern Browsers */ url($font_path+'SourceSansPro-Semibold.woff') format('woff'); /* Modern Browsers */
//     font-style: normal;
//     font-weight: 600;
//     text-rendering: optimizeLegibility;
// }
// // [END] SOURCE SANS //
// // ***************** //

// ********** //
// ROBOTOMONO //
// LIGHT
@font-face {
    font-family: 'RobotoMono';
    src: url($font_path+'RobotoMono-Thin.eot'); /* IE9 Compat; Modes */
    src: url($font_path+'RobotoMono-Thin.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url($font_path+'RobotoMono-Thin.woff2') format('woff2'),
        /* Modern Browsers */ url($font_path+'RobotoMono-Thin.woff') format('woff'); /* Modern Browsers */
    font-style: normal;
    font-weight: 200;
    text-rendering: optimizeLegibility;
}
// REGULAR
@font-face {
    font-family: 'RobotoMono';
    src: url($font_path+'RobotoMono-Regular.eot'); /* IE9 Compat; Modes */
    src: url($font_path+'RobotoMono-Regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url($font_path+'RobotoMono-Regular.woff2') format('woff2'),
        /* Modern Browsers */ url($font_path+'RobotoMono-Regular.woff') format('woff'); /* Modern Browsers */
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}
// [END] ROBOTOMONO //
// **************** //

// *********** //
// UI Essential //

// // NORMAL
// @font-face {
//     font-family: 'UI essential';
//     src: url($font_path+'uiessential72-regular-webfont-full.eot'); /* IE9 Compat; Modes */
//     src: url($font_path+'uiessential72-regular-webfont-full.eot?#iefix') format('embedded-opentype'),
//         /* IE6-IE8 */ url($font_path+'uiessential72-regular-webfont-full.woff2') format('woff2'),
//         /* Modern Browsers */ url($font_path+'uiessential72-regular-webfont-full.woff') format('woff'); /* Modern Browsers */
//     font-style: normal;
//     font-weight: 400;
//     text-rendering: optimizeLegibility;
// }
// [END] UI ESSENTIAL //
// ****************** //

/*****************************************/
/*****************************************/
/*****************************************/

@font-face {
    font-family: 'Poppins';
    src: url($font_path+'PoppinsFix-400.eot'); /* IE9 Compat; Modes */
    src: url($font_path+'PoppinsFix-400.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url($font_path+'PoppinsFix-400.woff2') format('woff2'),
        /* Modern Browsers */ url($font_path+'PoppinsFix-400.woff') format('woff'); /* Modern Browsers */
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Poppins';
    src: url($font_path+'PoppinsFix-600.eot'); /* IE9 Compat; Modes */
    src: url($font_path+'PoppinsFix-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url($font_path+'PoppinsFix-600.woff2') format('woff2'),
        /* Modern Browsers */ url($font_path+'PoppinsFix-600.woff') format('woff'); /* Modern Browsers */
    font-style: normal;
    font-weight: 600;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Poppins';
    src: url($font_path+'PoppinsFix-700.eot'); /* IE9 Compat; Modes */
    src: url($font_path+'PoppinsFix-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url($font_path+'PoppinsFix-700.woff2') format('woff2'),
        /* Modern Browsers */ url($font_path+'PoppinsFix-700.woff') format('woff'); /* Modern Browsers */
    font-style: normal;
    font-weight: 700;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Quicksand';
    src: url($font_path+'quicksand-regular-webfont.eot'); /* IE9 Compat; Modes */
    src: url($font_path+'quicksand-regular-webfont.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url($font_path+'quicksand-regular-webfont.woff2') format('woff2'),
        /* Modern Browsers */ url($font_path+'quicksand-regular-webfont.woff') format('woff'); /* Modern Browsers */
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Quicksand';
    src: url($font_path+'quicksand-medium-webfont.eot'); /* IE9 Compat; Modes */
    src: url($font_path+'quicksand-medium-webfont.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url($font_path+'quicksand-medium-webfont.woff2') format('woff2'),
        /* Modern Browsers */ url($font_path+'quicksand-medium-webfont.woff') format('woff'); /* Modern Browsers */
    font-style: normal;
    font-weight: 600;
    text-rendering: optimizeLegibility;
}

// *********** //
// UI thin //
// NORMAL
@font-face {
    font-family: 'UI thin';
    src: url($font_path+'UI-thin-Regular.eot'); /* IE9 Compat; Modes */
    src: url($font_path+'UI-thin-Regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url($font_path+'UI-thin-Regular.woff2') format('woff2'),
        /* Modern Browsers */ url($font_path+'UI-thin-Regular.woff') format('woff'); /* Modern Browsers */
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}
// [END] UI thin //
