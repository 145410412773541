.datetime {
    flex: 0 0 6.875rem;
    display: flex;
    justify-content: center;
    align-self: center;
    text-align: center;
    margin: 0;
    // color: $black;
    font-family: RobotoMono;
    .from,
    .to {
        font-weight: 400;
        font-size: 1rem;
    }
    .from-today-to,
    .allday {
        display: block;
        font-size: 0.75rem;
        letter-spacing: -0.01em;
        line-height: 1em;
        padding: 0;
        &.alone {
            // color: $grey_cold_6;
            align-self: center;
        }
    }
    .from {
        margin-top: 0.3125rem;
    }
    .from.date,
    .to.date {
        // color: $grey_cold_6;
        font-size: 0.8125rem;
    }
    .arrow-down {
        @extend %UI-icon;
        @extend %UI-arrow-down;
        // color: $grey_cold_3;
        opacity: 0.4;
        font-size: 0.8125rem;
        display: block;
    }
    .separator {
        font-size: 0.8125rem;
        display: block;
        &:before {
            content: ' ';
        }
    }
    .timezone {
        color: $gray-500;
    }
    .time {
        font-family: 'Poppins';
        font-weight: 600;

        .allday {
            font-family: RobotoMono;
            font-weight: 400;
            // color: $grey_cold_6;
        }
    }
}

@include for-size($tablet-landscape-up) {
    .event-detail {
        .datetime {
            font-size: 2rem;
        }
    }
}
