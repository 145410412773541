$public-navbar-width: 940px;

.anonymous-layout {
    background-color: $white;
    padding-bottom: 4em;

    .public-navbar {
        .container {
            margin: 0 auto;
            height: 12.4375em;
            position: relative;
        }

        background-color: $button-blue;

        img {
            height: 2.5rem;
        }

        h1 {
            color: $white;
            margin-top: 0.5em;
            font-size: 3em;
            font-weight: 700;
        }

        a:hover {
            text-decoration: none;
        }

        .languages-selector {
            display: inline-block;
            position: absolute;
            top: 2.5em;
            right: 1em;
            font-family: 'Poppins';
            &:after {
                content: '\25be';
                color: $white;
                position: absolute;
                top: 0.9rem;
                right: 0;
            }

            select {
                background: none;
                margin: 0;
                padding: 0 1rem 0 0;
                border: none;
                color: $white;

                option {
                    color: $black;
                }
            }
        }
    }

    .app-icon {
        margin-top: -3.3em;
        width: 67px;
        float: right;
    }

    .agenda-back {
        .container {
            margin: 0 auto;
        }

        height: 4em;
        padding: 0 1rem;
        background-color: $light-blue;
        a {
            color: $white;
            font-size: 1em;
            font-weight: 600;
            font-family: 'Poppins';
            text-decoration: none;

            &:hover span {
                text-decoration: underline;
            }

            i {
                font-size: 1.5em;
            }
        }
    }

    .anonymous-layout {
        .legal {
            .legal-content {
                background-color: white;

                ul {
                    padding: 1rem;
                }

                table {
                    margin-bottom: 1rem;
                    tr {
                        th,
                        td {
                            width: 50%;
                            border: 1px solid $menu-border;
                            vertical-align: top;
                            padding: 0.5rem;
                            font-size: 0.8rem;
                        }

                        th {
                            vertical-align: middle;
                        }
                    }
                }
            }

            .btn-back {
                @extend .btn-outline-strong;
                @extend .btn-center;
            }
        }
    }

    .help-container,
    .rules-container {
        margin: 0 auto;
        max-width: $public-navbar-width;

        .back {
            color: $button-blue;
            margin-top: 2em;
            font-family: 'Poppins';
            font-weight: 400;
            text-decoration: none;
            font-size: 1em;

            &:hover span {
                text-decoration: underline;
            }

            i {
                font-size: 1.5em;
            }
        }

        h2 {
            font-size: 2em;
            margin: 1em 0 0.5em 0;
        }

        h3 {
            margin: 2em 0 0.5em 0;
            padding-left: 0.5em;
        }
        .videos {
            padding-bottom: 4em;
            .video {
                margin-bottom: 4em;
                h3 {
                    padding-left: 0;
                }
            }
        }

        a {
            color: $button-blue;
        }

        .faq-section h3 {
            border-left: 5px solid $button-blue;
            padding-left: 0.5em;
            line-height: 1.5em;
            font-size: 1em;
        }

        p {
            margin: 0 1em;
        }

        .section {
            width: 100%;
            border-radius: 4px;
            background-color: $main-bg;
            margin: 0.75em 0;
            padding: 0.75em 1em;
            line-height: 2.5em;
            cursor: pointer;

            .question {
                display: flex;

                div {
                    flex-grow: 1;
                    font-size: 1em;
                    color: $black;
                    font-family: 'Poppins';
                    font-weight: 600;
                    margin: 0;
                    line-height: 1.5em;
                }

                i {
                    line-height: 0.65em;
                    width: 1em;
                    float: right;
                    font-size: 2em;
                    margin: 0;
                }
            }

            .response {
                font-family: 'Poppins';
                font-size: 0.875em;
                line-height: 1.4em;
                padding-top: 1em;

                p {
                    margin-left: 0;
                }

                ol,
                ul {
                    padding-inline-start: 1.5em;
                }

                h4 {
                    text-align: center;
                }

                .cards {
                    display: flex;
                    flex-flow: wrap;

                    .card {
                        flex: 0 0 48%;
                        margin: 1em 1%;
                        padding: 1em;
                        border: 1px solid $gray-400;
                        border-radius: 4px;
                        font-family: 'Poppins';
                        font-size: 1em;
                        line-height: 1.4em;

                        .number {
                            font-weight: bold;
                            font-size: 2em;
                            float: left;
                            margin: 0 1em 1em 0;
                        }

                        .text-response {
                            margin-bottom: 2em;
                        }

                        img {
                            margin: 0 auto;
                            max-width: 100%;
                        }
                    }
                }
            }
        }

        .help-section {
            @extend .clearfix;
            background-color: $main-bg;

            display: block;
            color: $black;
            font-weight: 600;
            text-decoration: none;
            font-family: 'Poppins';
            margin: 0.5em;
            padding: 0.5em 1em;
            line-height: 2.5em;

            i {
                float: right;
                font-size: 2em;
                margin: 0;
            }
        }

        .help-footer {
            h3 {
                font-size: 1em;
                padding-left: 0;
            }

            p {
                font-size: 0.75em;
                line-height: 1.4em;
                margin-left: 0;
            }
            .links {
                a {
                    color: $grey_cold_7;
                    text-decoration: underline;
                }
            }
        }
    }
}

@include for-size($tablet-landscape-up) {
    .anonymous-layout {
        .public-navbar {
            max-width: 100%;

            .container {
                min-width: $public-navbar-width;
            }
        }

        .agenda-back {
            height: 2.625em;

            .container {
                min-width: $public-navbar-width;
            }
        }

        .help-section-container {
            display: flex;
            padding-bottom: 4em;
            .help-section {
                flex-basis: 100/3 * 1%;
                padding: 0;
                text-align: center;
                height: 12.5em;
                line-height: 12.5em;
                border-radius: 4px;
            }
            a i {
                display: none;
            }

            .help-section {
                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .response {
            width: 80%;

            &.response-with-images {
                margin: 0 auto;
            }
        }

        .help-footer {
            display: flex;
            font-family: 'Poppins';
            font-weight: 400;

            .about {
                flex-basis: 100/3 * 2%;
                padding-right: 7em;
            }

            .linsk {
                flex-basis: 100/3 * 1%;
            }
        }
    }
}

@include for-size($tablet-landscape-down) {
    .anonymous-layout {
        main {
            padding: 0 0.75em;
        }

        h1 {
            line-height: 1em;
        }

        h2 {
            line-height: 1.2em;
        }

        .agenda-back {
            height: 3em;
            .container {
                min-width: 100%;
            }
        }

        .help-container {
            .section {
                .response {
                    h4 {
                        text-align: left;

                        &:after {
                            content: ':';
                        }
                    }

                    .cards {
                        flex: none;
                        display: block;
                        .card {
                            flex: none;
                            width: 100%;
                            display: block;

                            .number {
                                font-weight: normal;
                                font-size: 1em;
                                float: none;
                                margin: 0;

                                &:after {
                                    content: '. ';
                                }
                            }
                        }
                    }
                }
            }

            .help-section {
                margin-left: 0;
                margin-right: 0;
            }

            .videos {
                padding-bottom: 0;
            }
        }

        .legal {
            .btn-back {
                margin-bottom: $mobile-margin-bottom;
            }
        }
    }
}
