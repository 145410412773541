.events-wrapper {
    // width: calc(100% - #{$aside-width});
    overflow-y: auto;
    position: fixed; // Need for infinite scroll
    top: $header-height;
    bottom: 0;
    -webkit-overflow-scrolling: touch;
    width: 100%;

    .events {
        margin-bottom: 1rem;
        .day {
            h3 {
                font-family: 'Poppins';
                font-weight: 600;
                position: sticky;
                top: 0;
                z-index: 2;
                height: 3rem;
                font-size: 0.875rem;
                line-height: 3rem;
                margin: 0;
                padding: 0 1rem;
                color: $grey_cold_6;
                background-color: $main-bg;
                box-shadow: inset 0 2px 3px rgba($grey_cold_5, 0.25);
                border-bottom: 1px solid $border-color;
            }
        }
        .day.visible {
            h3 {
                background-color: black;
                color: white;
                box-shadow: 0 2px 7px rgba($grey_cold_5, 0.6);
                border-bottom: none;
            }
        }
        .day.visible ~ .day.visible {
            h3 {
                color: $grey_cold_6;
                background-color: $main-bg;
                box-shadow: inset 0 2px 3px rgba($grey_cold_5, 0.25);
                border-bottom: 1px solid $border-color;
            }
        }

        .event {
            display: flex;
            position: relative;
            border-top: 1px solid $border-color;
            background-color: $white;
            border-left: 0.5em solid transparent;
            padding-right: 1rem;
            margin: 0;

            &:first-child {
                border-top: none;
            }

            &:last-child {
                border-bottom: 1px solid $border-color;
            }

            &.topnews {
                border-left: 0.5rem solid $topnews;
            }

	    .status {
		background-color: $black;
		color: $white;
		font-size: 0.825em;
		font-weight: 600;
		border-radius: 2px;
		padding: 0.2em 0.5em 0.15em 0.5em;
		line-height: 1.125rem;
		margin-right: 0.5em;
		text-transform: uppercase;
	    }

            &.canceled {
                border-left: 0.5rem solid $topnews;
                .datetime,
                .dateline,
                .title a {
                    text-decoration: line-through;
                }
            }

            &.magazine {
                border-left: 0.5rem solid $feature;
            }

            .coverages {
                font-size: 0.8rem;
                position: absolute;
                top: 0.2rem;
                right: 0.5rem;
                color: $red;
                @include for-size($tablet-landscape-down) {
                    display: none;
                }
            }

            .datetime {
                color: $grey_cold_6;
                .time {
                    color: black;
                }
            }

            .text {
                .dateline {
                    font-size: 0.875rem;
                    font-weight: 600;
                    color: $grey_cold_6;
                    line-height: 1.125rem;
                    letter-spacing: -0.015em;
                    margin-top: 0.75rem;
                }

                .title {
                    $line-height: 1.25rem;
                    font-family: 'Poppins';
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: $line-height;
                    letter-spacing: -0.015em;
                    overflow: hidden;
                    max-height: calc(3 * #{$line-height});
                    margin: 0.1875rem 0 0.9rem 0;
                    a {
                        color: $black;
                    }
                }

                .canceled {
                    font-family: RobotoMono;
                    font-weight: 400;
                    font-size: 0.6875rem;
                    background-color: $refused;
                    color: $white;
                    text-transform: uppercase;
                    padding: 0.125em 0.75em;
                    margin-right: 0.5em;
                    border-radius: 2px;
                }
            }
        }
    }

    .info-wrapper.more-results {
        // width: 6rem;
        margin: 1rem;
    }

    .top-page {
        text-align: center;
        background: none;
        border: none;
        text-shadow: none;
    }
}

@include for-size($tablet-landscape-down) {
}

@include for-size($tablet-landscape-up) {
    .events-wrapper {
        top: $header-height-desktop;
        width: calc(100% - #{$aside-width});
        padding: 0 2rem;

        .events {
            max-width: $desktop-max-width;
            margin: auto;

            .day {
                margin-bottom: 1rem;
            }

            .day,
            .day.visible,
            .day.visible ~ .day.visible {
                h3 {
                    box-shadow: none;
                }
            }

            .event {
                padding: 0.875rem;
                &.topnews,
                &.canceled,
                &.magazine {
                    border-left-width: 1rem;
                }

		.status {
		    font-size: 0.7em;
		}

                .datetime {
                    box-sizing: border-box;
                    flex: 0 0 9.375rem;
                    padding-right: 1rem;
                }

                .text {
                    padding-right: 2rem;
                    .title {
                        font-size: 1.3rem;
                        line-height: 1.75rem;
                        max-height: 3.5rem;
                    }
                }
            }
        }
        .info-wrapper {
            max-width: $desktop-max-width;
            margin: auto;

            &.more-results {
                // width: 6rem;
                margin: auto;
            }
        }
    }
}
