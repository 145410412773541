@include for-size($tablet-landscape-up) {
    .profile-container {
        .actions {
            display: flex;
            flex-direction: row;

            .btn {
                margin-right: 1rem;
            }

            .btn:last-child {
                margin-right: 0;
            }
        }
    }
}
