.anonymous-layout {
    .rules-container {
        .tabs {
            .nav-tabs {
                a {
                    color: $black;
                }
            }
        }

        .legal-content {
            background-color: white;
            padding-top: 2em;

            h3 {
                margin-top: 0;
            }

            ul {
                padding-inline-start: 1.5em;
            }

            table {
                margin-bottom: 1rem;
                tr {
                    th,
                    td {
                        width: 50%;
                        border: 1px solid $menu-border;
                        vertical-align: top;
                        padding: 0.5rem;
                        font-size: 0.8rem;
                    }

                    th {
                        vertical-align: middle;
                    }
                }
            }
        }

        .back-button {
            margin-top: 2em;
        }

        .btn-back {
            @extend .btn-outline-strong;
            @extend .btn-center;
        }
    }
}

@include for-size($tablet-landscape-down) {
    .legal {
        .btn-back {
            margin-bottom: $mobile-margin-bottom;
        }
    }
}
