.search-form {
    .refinements {
        margin-top: 1rem;

        .refinement {
            padding: 1rem;
            background-color: rgba($grey_cold_1, 0.5);
            margin-bottom: 0rem;
            border-bottom: 1px solid $grey_cold_2;

            .fields {
                width: 100%;
                display: flex;
            }

            button {
                flex: 0 0 2em;
            }
        }

        .btn-criteria {
            cursor: pointer;
            color: $grey_cold_5;
            font-weight: 600;
            margin: 0 0 0.5rem 0;
            transition: color 0.3s cubic-bezier(0, 0.5, 0.6, 1);

            .text {
                color: $brand-blue;
                text-decoration: underline;
            }

            &:focus {
                outline: none;
            }

            &:hover {
                color: darken($grey_cold_5, 10%);
            }

            .icon {
                font-weight: 400;
                font-size: 2rem;
                margin: 0 0.25em;
            }
        }
    }

    .top-news-checkbox {
        padding-left: 0.5rem;
        margin-bottom: 1rem;
        transition: background-color, border 0.3s cubic-bezier(0, 0.5, 0.6, 1);
        border-left: 0 solid $topnews;

        label {
	    text-indent: 1.5em;
            cursor: pointer;
	    &:before {
		top: 1em;
		left: 0;
		margin-right: em;
	    }
        }

        &.checked {
            background-color: white;
            border-left-width: 0.75rem;
        }
    }
}

@include for-size($tablet-landscape-down) {
    .search-form {
        .refinement {
            .fields {
                flex-direction: column;
                margin-right: 10px;

                .select-type,
                .field {
                    margin-bottom: 5px;
                    padding: 0;
                }
            }

            button {
                height: 3em;
                flex: 0 0 3em;
                justify-content: center;
                align-self: center;
            }
        }
    }

    .search-container {
        .actions {
            margin-bottom: $mobile-margin-bottom;
        }
    }
}

@include for-size($tablet-landscape-up) {
    .search-form {
        .refinement {
            padding: 1rem;
            background-color: rgba($grey_cold_1, 0.5);
            margin-bottom: 0.5rem;

            .fields {
                .select-type {
                    flex: 1 1 35%;
                    padding-right: 1em;
                }

                .field {
                    flex: 1 1 55%;
                    padding-right: 1em;
                }
            }

            button {
                flex: 0 0 2em;
            }
        }
    }

    .search-container {
        .actions {
            display: flex;
            flex-direction: row;

            .btn {
                margin-right: 1rem;
            }

            .btn:last-child {
                margin-right: 0;
            }
        }
    }
}
