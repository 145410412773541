.public-layout {
    .public-navbar {
        background-color: $brand-blue;
        padding: 0;

        .navbar-brand {
            img {
                height: 4rem;
            }
        }
    }
}

.login-container {
    &.content {
        margin: 2rem auto;
        max-width: 23%;

        .login-form {
            background-color: $white;
            padding: 2rem 1.25rem;
            // border-bottom: 0.45rem solid $border-color;
            box-shadow: 0 0 7px rgba($grey_cold_7, 0.15);
        }

        .sign-up {
            padding: 1.25rem;
        }
    }

    .help {
        text-align: center;

        a {
            color: $gray-600;
            font-weight: bold;
            text-decoration: underline;
        }
    }

    @include for-size($tablet-landscape-down) {
        &.content {
            margin: 0;
            max-width: none;
        }
    }

    @include for-size($tablet-landscape-up) {
        &.content {
            margin: 2rem auto;
            min-width: 50%;
            max-width: 75%;
        }
    }

    @include for-size($tablet-landscape-up) {
        &.content {
            margin: 2rem auto;
            min-width: 35%;
            max-width: 50%;
        }
    }

    @include for-size($desktop-up) {
        &.content {
            margin: 2rem auto;
            min-width: 25%;
            max-width: 35%;
        }
    }

    @include for-size($big-desktop-up) {
        &.content {
            margin: 2rem auto;
            min-width: 20%;
            max-width: 25%;
        }
    }

    .btn-login {
        @extend .btn-primary;
        @extend .btn-center;
        width: 100%;
    }

    .btn-contact {
        @extend .btn-secondary;
        @extend .btn-center;
        display: inline-block;
        width: 100%;
        padding: 1rem;
    }
}

.cookie-consent {
    background-color: $black;
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 1em;
    display: flex;
    justify-content: center;

    .cookie-consent-text {
        font-family: Quicksand-Medium;
        color: $white;
        letter-spacing: 0;
        margin: auto;
        padding-bottom: 1em;
    }

    .btn-cookie-consent {
        margin: auto;
    }

    @include for-size($tablet-landscape-down) {
        display: block;
    }
}
