header {
    position: fixed;
    top: 0;
    right: 0;
    background-color: $white;
    box-shadow: 0 2px 4px rgba($grey_cold_4, 0.3);
    z-index: 40;

    .navbar {
        padding: 0;
        height: $header-height;
    }

    .navbar-toggler {
        opacity: 1;
        display: block;
        font-size: 2rem;
        color: $grey_cold_6;
        padding: 0 0.75rem 0.3rem 0.75rem;

        transition-timing-function: ease-in-out;
        transition-duration: 0.3s;
        transition-property: opacity;

        &:focus {
            outline: 0;
        }
    }

    .actions {
        height: $header-height;
        margin: 0;
        padding: 0;

        .nav-item {
            display: inline-block;
            width: 3rem;
            height: 3rem;
            line-height: 3rem;
            text-align: center;
            a {
                text-decoration: none;
            }
            .icon {
                background-color: transparent;
                font-size: 2rem;
                color: $grey_cold_6;
                margin-top: 2px;
                transition: color 0.3s cubic-bezier(0, 0.5, 0.6, 1);

                &:hover {
                    color: black;
                }
            }
            .label {
                display: none;
            }
            button.active {
                .icon {
                    color: $link_color;
                }
            }
        }
    }
}

@include for-size($tablet-landscape-down) {
    header {
        width: 100%;
    }
}

@include for-size($tablet-landscape-up) {
    header {
        width: calc(100% - #{$aside-width});
        z-index: 0;
        padding: 0 2rem;

        background-color: $main-bg;
        box-shadow: none;

        .navbar {
            height: $header-height-desktop;
            max-width: $desktop-max-width;
            margin: auto;

            .nav-item {
                // margin: 0 0.5rem;
                margin: 0;
                width: auto;

                .icon {
                    margin-left: 1rem;
                }

                .label {
                    display: inline;
                    font-weight: 600;
                    font-size: 0.875rem;
                    color: $grey_cold_6;
                    opacity: 0.6;
                    transition: opacity 0.2s ease-in-out;
                    // margin-right: 0.5rem;

                    &:hover {
                        opacity: 1;
                    }
                }

                button.active {
                    .label {
                        color: $link_color;
                    }
                }
            }
        }

        .navbar-toggler {
            display: none;
        }
    }
}
