.top-news-checkbox {
    line-height: 3.25rem;
    height: 3.25rem;
    border-top: 1px solid $grey_cold_3;
    border-bottom: 1px solid $grey_cold_3;
}

input,
input[type='date'],
input.standard,
select,
.custom-select {
    height: 3.25rem; // @TODO: à généraliser pour tous les formulaires
    font-weight: 600;
    border-color: $grey_cold_3;
    color: $grey_cold_6;
    background-color: white;
    border-radius: 0;
}

input.standard {
    margin-top: 0.75rem;
}

label {
    width: 100%;
}

label {
    margin-bottom: 0.75rem;
    font-weight: 600;
}

.react-select {
    .react-select__control {
        color: $grey_cold_6;
        border: 1px solid $grey_cold_3;
        border-radius: 0;
        height: 3.25rem;
        box-sizing: border-box;
        font-weight: 600;
        background-color: white;

        &:hover {
            border-color: $grey_cold_3;
        }

        &.react-select__control--is-focused {
            border-color: #89d5fe;
            outline: 0;
            -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(137, 213, 254, 0.5);
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(137, 213, 254, 0.5);
        }

        .react-select__input {
            color: $grey_cold_6;
            input {
                height: 3.25rem;
            }
            border-radius: 0;
        }

        .react-select__value-container {
            border-radius: 0;
            height: 3.25rem;
            box-sizing: border-box;

            .react-select__single-value {
                color: $grey_cold_6;
            }

            .react-select__single-value ~ div {
                border-radius: 0;
                height: 3.25rem;
                box-sizing: border-box;
            }

            .react-select__placeholder {
                color: $grey_cold_4;
            }

            .react-select__input {
                color: $grey_cold_6;
            }
        }
    }

    .react-select__menu {
        box-sizing: border-box;
        margin: 0;
        border-radius: 0;
        left: 1px;
        width: calc(100% - 2px);

        // .react-select__menu-notice--no-options {
        //     visibility: hidden;
        // }
        // .react-select__menu-notice--no-options:after {
        //     @extend %UI-icon;
        //     @extend %UI-edit:before;
        //     font-size: 1.5rem;
        //     visibility: visible;
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     padding: 0.25rem 0.75rem;
        // }
    }
}
