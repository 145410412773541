$item-height: 3.25rem;

aside {
    box-sizing: border-box;
    background-color: $main-bg;
    color: $black;
    transition-timing-function: cubic-bezier(0.5, 0, 0.2, 1);
    transition-duration: 0.3s;
    transition-property: right, visibility;
    box-shadow: 0 0 7px rgba($grey_cold_6, 0.5);
    z-index: 50;

    .sidebar-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        background-color: black;
        color: white;
        height: $header-height;
        line-height: 1.5rem;
        padding: 0;
        position: relative;
        z-index: 60;
        box-shadow: 0 2px 4px rgba($grey_cold_4, 0.3);

        .logo {
            padding: 0 0.5rem;
            background-color: transparent;
            height: $header-height;
            .navbar-brand {
                padding-top: 8px;
                margin-right: 0;
                img {
                    height: 2.5rem;
                }
            }
            flex-grow: 0;
        }

        .beta {
            flex-grow: 0;
            margin-left: 1rem;
        }

        .navbar-toggler {
            flex-grow: 1;
            box-sizing: border-box;
            line-height: 2rem;
            font-size: 2rem;
            padding: 0 0.75rem 0.3rem 0.75rem;
            color: white;
            text-align: right;

            &:focus {
                outline: 0;
            }
        }
    }

    .sidebar-wrapper {
        background-color: $main-bg;
        z-index: 50;
        -webkit-overflow-scrolling: touch;
    }

    .info {
        padding: 1rem;
        padding-bottom: 2rem;
        text-align: center;
        font-size: 0.875rem;
        color: $grey_cold_6;
    }

    .section {
        div[role='button'] {
            &:focus {
                outline: 0;
            }
        }

        &:focus {
            outline: 0;
        }

        a {
            color: black;
            &:hover {
                text-decoration: none;
            }
        }

        h4,
        button[type='button'] {
            display: block;
            line-height: $item-height;
            background-color: $white;
            padding: 0 1.125rem;
            margin: 0;
            border-bottom: 1px solid $grey_cold_1;
            cursor: pointer;

            &[type='button'] {
                color: $black;
                text-shadow: none;
            }

            .icon {
                font-size: 2rem;
                font-weight: 400;
                line-height: $item-height;
                display: inline-block;
                margin-right: 1rem;
                color: $grey_cold_6;

                &.open-close {
                    margin-right: 0;
                }
            }

            img {
                margin-right: 1.5rem;
            }

            a {
                color: $black;
            }

            .open-close {
                cursor: pointer;
                float: right;
            }
        }

        .icon-svg {
            padding-left: 3px;
        }
    }

    .filters {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                font-weight: 600;
                height: $item-height;
                line-height: $item-height;
                padding: 0 1.125rem;
                border-bottom: 1px solid $grey_cold_1;
                position: relative;
                display: grid;
                grid-template-columns: auto 2em;

                a {
                    padding: 0;
                    font-size: 0.9375rem;
                    display: block;
                    color: $grey_cold_6;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;

                    &.active {
                        &:before {
                            content: '•';
                            margin-right: 0.5em;
                        }
                    }
                }

                a,
                button[type='button'] {
                    .icon {
                        color: $grey_cold_3;
                        font-size: 1.5rem;
                        // transition: color 0.3s cubic-bezier(0, 0.5, 0.6, 1);

                        &:hover {
                            color: $grey-cold-5;
                        }

                        &:active,
                        &.selected {
                            color: $button-blue;
                        }
                    }
                }

                .actions {
                    position: absolute;
                    top: 0;
                    right: 1em;
                    visibility: visible;

                    .action {
                        width: 2.1rem;
                        cursor: pointer;
                        color: $grey_cold_2;
                    }

                    button[type='button'] {
                        background-color: transparent;
                        padding: 0;
                        margin-right: 0em;
                        border: 0;
                    }
                }

                @include for-size($tablet-landscape-up) {
                    .actions {
                        visibility: hidden;

                        .action {
                            .selected {
                                visibility: visible;
                            }
                        }
                    }

                    &:hover .actions {
                        visibility: visible;
                    }
                }
            }
        }
    }

    .filters {
        .actions {
            display: grid;
            grid-template-columns: 47% 47%;
            grid-column-gap: 6%;
        }

        .edit {
            color: $grey-cold-3;
        }
    }
}

@include for-size($tablet-landscape-down) {
    aside {
        height: 100vh;
        visibility: hidden;
        right: 100%;
        width: 100%;
        // height: calc(100% - #{$header-height});
        position: fixed;
        overflow-y: auto;

        .sidebar-header {
            .navbar-toggler {
                display: block;
                opacity: 0;
            }

            .logo {
                .navbar-brand {
                    svg path {
                        fill: $white;
                    }
                }
            }
        }

        .sidebar-wrapper {
            position: relative;
            // position: fixed;
            // top: $header-height;
            bottom: 0;

            width: 100%;
            $margin-bottom: 5rem;
            height: calc(100vh - #{$header-height} - #{$margin-bottom});
            margin-bottom: $margin-bottom;
        }

        &.open {
            right: 0;
            visibility: visible;

            .navbar-toggler {
                opacity: 1;
            }
        }
    }
}

@include for-size($tablet-landscape-up) {
    aside {
        box-shadow: none;
        margin-left: $header-height-desktop;

        .sidebar-header {
            justify-content: normal;
            box-shadow: none;
            background-color: $main-bg;

            .logo {
                .navbar-brand {
                    margin: auto;
                }
            }

            .navbar-toggler {
                display: none;
            }
        }

        .sidebar-wrapper {
            $margin-top: 1rem;
            $margin-bottom: 3rem;
            height: calc(100vh - calc(2 * #{$header-height-desktop}) - #{$margin-top} - #{$margin-bottom});
            margin-top: $margin-top;
            margin-bottom: $margin-bottom;

            .section {
                margin-bottom: 0.5rem;
                h4 {
                    cursor: pointer;
                }
            }

            .filters {
                ul {
                    li:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}
